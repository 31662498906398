import type { ParamMatcher } from '@sveltejs/kit';
import { categorySecondLevel } from './generated/category-level-roots';

const _ROOTS = ['pferdesport', 'bootsport', 'hundesport', 'faq'];

export const match: ParamMatcher = (param) => {
	if (param === 'product-redirect') {
		return true;
	}
	if (param.toLowerCase() !== param) {
		return false;
	}
	if (_ROOTS.some((root) => param.startsWith(root)) || categorySecondLevel.includes(param)) {
		return false;
	}
	if (param.length === 8) {
		return /[^0-9a-f]/gm.test(param);
	}

	return true;
};
