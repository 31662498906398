// hooks.client.js
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { isProd } from '$lib/utils';
import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';
// or
// import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'

const onError = init(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		environment: isProd() ? 'production' : 'preview',
		ignoreErrors: [
			'TypeError: Failed to fetch',
			'TypeError: Load failed',
			'TypeError: Error in input stream',
			'TypeError: Error in body stream',
			'NetworkError when attempting to fetch resource.',
			"Can't find variable: _AutofillCallbackHandler",
			'"cmpLayer":"PRIVACY_BUTTON"',
			'InitializationError'
		]
	}
});

export const handleError: HandleClientError = dev
	? (ctx) => {
			console.error(ctx.error);

			return {
				message: ctx?.message || '',
				status: ctx?.status || ''
			};
		}
	: onError((e, sentryEventId) => {
			console.error('client-err', e.error);

			return {
				...e,
				sentryEventId
			};
		});
