import type { ParamMatcher } from '@sveltejs/kit';
import { categoryRoots } from './generated/category-roots';

export const match: ParamMatcher = (param) =>
	[
		...categoryRoots,
		'hund',
		'pferd',
		'boot',
		'pferdesport-root',
		'hundesport-root',
		'bootsport-root',
		'sprenger-me-root'
	].includes(param) &&
	![
		'cart',
		'customer',
		'imprint',
		'content',
		'faq',
		'search',
		'usa',
		'zahlung-versand',
		'privacy',
		'widerrufsrecht',
		'magazin',
		'checkout',
		'agb',
		'about',
		'order-confirmation'
	].some((e) => param.includes(e));
